<template>
    <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                Resim: 
                <input name="file" id="file" type="file" style="width: 75%;float: right;" @change="handleFileUpload($event)"/>
            </CCol>
            <CCol>
                <CInput
                    label="URL: "
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.imgUrl"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Sıralama: "
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.displayOrder"
                />
            </CCol>
            <CCol>
                <CRow style="margin-top: 20px">
                    <CCol sm="4">
                        Birincil
                        <input v-model="data.mainFlag" type="checkbox" name="select" value="value" style="height:16px; width:16px;">
                    </CCol>
                    <CCol sm="4">
                        İkincil 
                        <input v-model="data.secondaryFlag" type="checkbox" name="select" value="value"  style="height:16px; width:16px;">
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
   </CForm>
</template>

<script>
   export default{
       name: "ProductImageForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       methods:{
        handleFileUpload: function(event) {
            let file = event.target.files[0]
            let status = true
            let msg = {}
            if(file.size > 1500000){
                status = false
                msg = {
                    msg: 'Görsel boyutu en fazla 1.5 mb olabilir',
                    header: 'Hata',
                    color: 'danger'
                }
            }

            if(status){
                this.data.file = file;
            }else{
                event.target.value = ''
                this.$store.commit('showToast', msg)
            }
        },
      },
       data: function(){
           return{
                data: this.params ? {...this.params} : {},
           }
       },
   }
</script>